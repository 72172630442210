<template>
    <div class="orders">
        <!-- snackbar случайно удаленных заказов -->
        <DeletedOrdersSnackbar
        :text="snackbarText"
        v-model="snackbar"
        @execute="deleteOrder"
        @cancel="snackRestoreOrder"
        >

        </DeletedOrdersSnackbar>
        
        <v-row style="max-width: 1800px" class="mx-auto">
            <v-col cols="12">
                <v-card class="mx-auto elevation-6">
                    <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            <!-- {{ $t('orders.list_of_orders', { p1: orders.length, p2: ordersComplete, p3: ordersFee }) }} -->
                            Всего заказов: {{ orders.length }}, выполнено: {{ ordersComplete }}, {{ userIsPoint ? 'расходы' : 'выручка' }}: {{ ordersFeeService }} ₽
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn-toggle v-model="calendar" tile group mandatory dense>
                            <v-btn value="left" @click="onClickToday"> <v-icon>mdi-calendar</v-icon> {{ $t('orders.buttons.today') }} </v-btn>
                            <v-btn value="center" @click="onClickYesterday"> <v-icon>mdi-calendar-arrow-left</v-icon> {{ $t('orders.buttons.yesterday') }} </v-btn>
                            <v-btn value="right" @click="onClickMonth"> <v-icon>mdi-calendar-month</v-icon> {{ $t('orders.buttons.month') }} </v-btn>
                            <v-menu v-model="menu_calendar" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-btn text class="menu-button" v-on="on">
                                        <v-icon class="mr-1">mdi-calendar-multiple</v-icon>
                                        {{ $t('orders.buttons.range') }}
                                    </v-btn>
                                </template>
                                <div @keydown.esc="closeCalendar" tabindex="0">
                                    <v-date-picker range v-model="dates" locale="ru-ru" selected-items-text="Выбран период" :first-day-of-week="1" @change="onClickChangeDate"> </v-date-picker>
                                </div>
                            </v-menu>
                        </v-btn-toggle>

                        <v-spacer></v-spacer>

                        <DeletedOrdersDialog v-model="popupDeletedOrder" :ordersCount="deletedOrdersCount">
                            <tableDeleted :data="{ deleteData: deletedOrdersData, orderData: orders }" @successRestore="restoreStatus"></tableDeleted>
                        </DeletedOrdersDialog>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span class="tooltip-text" v-bind="attrs" v-on="on">
                                    <v-btn @click="onClickDownloadData" class="mx-4 px-2" tile icon rounded>
                                        <v-img src="../../assets/images/excel-300.png" width="40"></v-img>
                                    </v-btn>
                                </span>
                            </template>
                            <span>Выгрузка данных в таблицу</span>
                        </v-tooltip>

                        <v-btn icon @click="onClickUpdate">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-title class="pt-0 pb-2 d-flex">
                        <v-select v-if="!userIsService" class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" @change="updateData" :items="filter_services" name="service" item-text="name" item-value="id" v-model="filter_service" label="По сервису" hide-details></v-select>
                        <v-select v-if="!userIsPoint" class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" @change="updateData" :items="filter_points" name="point" item-text="name" item-value="id" v-model="filter_point" label="По точке" hide-details></v-select>
                        <v-select class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" @change="updateData" :items="filter_workers" name="worker" item-text="name" item-value="tid" v-model="filter_worker" label="По сервисмену" hide-details></v-select>
                        <v-select class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" @change="updateData" :items="filter_order_modes" name="order_mode" v-model="filter_order_mode" label="По типу" hide-details></v-select>
                 
                        <v-spacer></v-spacer>

                        <v-text-field style="width: 340px; flex: initial" v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
                    </v-card-title>

                    <v-data-table
                        :mobile-breakpoint="0"
                        dense
                        no-data-text="Нет данных"
                        no-results-text="Нет данных, попробуйте изменить запрос"
                        :footer-props="{
                            pageText: '{0} - {1} из {2}',
                            itemsPerPageText: 'Показывать на странице',
                            itemsPerPageAllText: 'все',
                            showFirstLastPage: true,
                            itemsPerPageOptions: [25, 50, 100, -1],
                        }"
                        :headers="headers"
                        :search="search"
                        :items="orders"
                        item-key="index"
                        :loading="loading"
                        :loading-text="$t('common.loading')"
                        @click:row="onClickOrderEdit($event)"
                    >

                        <template v-slot:[`item.options.marketingSource`]="{ item }">
                            <span>{{ marketingSource(item.options.marketingSource) }}</span>
                        </template>
                        
                        <template v-slot:[`item.name`]="{ item }">
                            <b>{{ item.name }}</b>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                            <div class="d-flex">
                                <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <!-- <span v-bind="attrs" v-on="on" class="item-order d-inline-block mx-3" :style="orderStatusStyle(order)">{{ order.id }}</span> -->
                                        <v-chip v-bind="attrs" v-on="on" :color="orderStatusColorHtml(item.status)" text-color="white">
                                            <v-icon size="small" class="mr-1">{{ orderStatusIcon(item.status) }}</v-icon>
                                            {{ orderStatusStr(item.status,true) }}
                                        </v-chip>
                                    </template>

                                    <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                        <div class="order-card" v-html="orderContent(item,true)+item.file_link"></div>
                                    </v-card>
                                </v-menu>
                                <v-btn class="mt-1" v-if="!userIsService && item.status != config.order.status.complete && (item.status != config.order.status.new || item.worker_tid)" outlined text tile x-small @click="onClickOrderStatus(item)"><v-icon small>mdi-pencil-outline</v-icon></v-btn>
                            </div>
                        </template>

                        <template v-slot:[`item.finish_addr`]="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <td class="tooltip-text" v-bind="attrs" v-on="on">
                                        <span>{{ item.finish_addr.substr(0, 24) }}</span>
                                    </td>
                                </template>
                                <span>{{ item.finish_addr }}</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:[`item.point_id`]="{ item }">
                            <b>{{pointNameGet(item.point_id)}}</b>
                        </template>
                        <template v-slot:[`item.service_name`]="{ item }">
                            <b>{{item.service_name}}</b>
                        </template>
                        <template v-slot:[`item.worker_name`]="{ item }">
                            <v-avatar size="24" class="mr-2" v-if="item.worker_name != '--'">
                                <img alt="worker" :src="`${config.api.delivery + 'user_avatar?tid=' + item.worker_tid}`" />
                            </v-avatar>
                            <b>{{ item.worker_name.length > 20 ? item.worker_name.substr(0, 20) + '...' : item.worker_name }}</b>
                        </template>

                        <template v-if="!userIsPoint" v-slot:[`item.percent`]="{ item }">
                            {{ item.percent }}%
                        </template>

                        <template v-if="!userIsPoint" v-slot:[`item.payForWorker`]="{ item }">
                            {{ item.payForWorker }}
                        </template>

                        <template v-slot:[`item.date`]="{ item }"> {{ item.mode.split(' ')[0] }} {{ timeToString(item.date) }}</template>

                        <template v-slot:[`item.date_ready`]="{ item }">
                            {{ timeToString(item.date_ready) }}
                        </template>

                        <template v-slot:[`item.date_delivery`]="{ item }">
                            {{ timeToString(item.date_delivery) }}
                        </template>

                        <template v-slot:[`item.date_accept`]="{ item }">
                            <span class="mr-2">{{ timeToString(item.date_accept) }}</span>
                            <v-chip v-if="item.date_accept" class="px-1 mb-1" :color="timeColorHtml(item.date_accept, item.date_ready)" x-small label text-color="white">
                                {{ timeDiff(item.date, item.date_accept) }}
                            </v-chip>
                        </template>

                        <template v-slot:[`item.date_complete`]="{ item }">
                            <span class="mr-2">{{ timeToString(item.date_complete) }}</span>
                            <v-chip v-if="item.date_complete" class="px-1 mb-1" :color="timeColorHtml(item.date_complete, item.date_delivery)" x-small label text-color="white">
                                {{ timeDiff(item.date_complete, item.date_delivery) }}
                            </v-chip>
                        </template>

                        <template v-slot:[`item.source`]="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <td class="tooltip-text" v-bind="attrs" v-on="on">
                                        <v-avatar size="24">
                                            <img alt="source" :src="iconSource(item)" />
                                        </v-avatar>
                                    </td>
                                </template>
                                <span>{{ tooltipSource(item) }}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <EditOrderDialog
            v-model="dialogEdit"
            :header="dialogHeader"
            :currentItemStatus="currentItem.status"
            :selectedItem="selectedItem"
            :userIsService="userIsService"
            :workers_list="workers_list"
            :disBtn="disBtn"
            @save="onSaveDialogEdit"
            @delete="onDeleteBtnDialogEdit"

        >

        </EditOrderDialog>
        

        <OrderStatusDialog 
            v-model="dialogStatus" 
            :header="dialogHeader"
            :currentItemStatus="currentItem.status"
            :worker_tid="currentItem.worker_tid"
            :dialogContent="dialogContent"
            @complete="onClickOrderComplete()"
            @new="onClickOrderNew()"
            @delete="onClickOrderDelete()"
        ></OrderStatusDialog>
        
        <span class="pb-16 d-block" />

    </div>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'

    moment.locale('ru')

    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'
    import Notify from '../../mixins/Notify.js'
    //подключаем таблицу "Удаленные заказы"
    import tableDeleted from './tableDeleted.vue'
    import OrderStatusDialog from './dialogs/OrderStatusDialog.vue'
    import EditOrderDialog from './dialogs/EditOrderDialog.vue'
    import DeletedOrdersDialog from './dialogs/DeletedOrdersDialog.vue'
    import DeletedOrdersSnackbar from './DeletedOrdersSnackbar.vue'

    export default {
        components: {
            tableDeleted,
            OrderStatusDialog,
            EditOrderDialog,
            DeletedOrdersDialog,
            DeletedOrdersSnackbar,
        },
        mixins: [DateTime, Status, Orders, Notify],
        data() {
            return {
                // *** Удаленные заказы ***
                //snackbar случайно удаленных заказов
                snackbar: false,
                snackbarText: '',
                snackbarTime: 5000,
                currentTime: 0,
                //отключение кнопки удаления (был баг что в если во время snackbar открыть другой заказ и пытаться непрерывно кликать по кнопке удалить (которая в момент удаления disabled), то в момент когда пропадает snackbar и кнопка активируется можно успеть передать параметр закликанного заказа в currentDeleteItem и досрочно отправить этот заказ в notify предыдущего удаления. По факту удалился предыдущий заказ а в notify полетел закликанный.
                disBtn: false,
                //сбрасывает interval
                snackbarCancelInterval: false,
                //фиксирует удаленный заказ для snackbar (был баг что в процессе удаления, когда висит snackbar, при нажатии на другие заказы он ловил другие заказы и отправлял их в notify как удаленные)
                currentDeleteItem: {},

                // переключатель попап удаленных заказов
                popupDeletedOrder: false,
                // данные по удаленным заказам
                deletedOrdersData: [],
                // *** Удаленные заказы конец ***

                dates: [],
                calendar: 0,
                menu_calendar: false,
                loading: false,
                search: '',
                searchDeleted: '',
                ordersComplete: '--',
                ordersFeeService:'--',
                orders: [],
                points: [],
                workers: [],
                workers_list:[],
                filter_point: 0,
                filter_points: [],
                filter_worker: 0,
                filter_workers: [],
                filter_service: 0,
                filter_services: [],
                filter_order_mode: 0,
                filter_order_modes: [
                    {
                        text: '< Все >',
                        value: 0,
                    },
                    {
                        text: '⏰ Предзаказ',
                        value: 1,
                    },
                    {
                        text: '🔥 Срочный заказ',
                        value: 2,
                    },
                ],
                headers: [],
                orderStatuses: [],
                timer: null,
                dialogStatus: false,
                dialogEdit: false,
                dialogHeader: '',
                dialogContent: '',
                config,
                currentItem: {},
                selectedItem: {},
                changeFlag: false,
                d1: null,
                d2: null,
                userIsPoint: false,
                userIsService:false,
                guestNameClick: false,
                guestCreateClick: false,
                promptFeeUpdate: false,
            }
        },
        created() {
            this.userIsPoint = this.$store.state.user.role == config.user.role.point
            this.userIsService = this.$store.state.user.role==config.user.role.service
            this.headers = [
                {
                    text: 'Сист.',
                    value: 'source',
                    sortable: false,
                },
                {
                    text: 'Название',
                    value: 'name',
                },
                {
                    text: 'Статус',
                    value: 'status',
                },

                {
                    text: 'Адрес заказа',
                    sortable: false,
                    value: 'finish_addr',
                },
                {
                    text: 'Заказчик',
                    sortable: true,
                    value: 'point_id',
                },
                {
                    text: 'Сервис',
                    sortable: true,
                    value: 'service_name',
                },
                {
                    text: 'Сервисмен',
                    sortable: true,
                    value: 'worker_name',
                },
                {
                    text: 'Цена заказа',
                    sortable: true,
                    value: 'pay_cost',
                },
                {
                    text: 'Когда нужно выполнить',
                    sortable: true,
                    value: 'date_delivery',
                },
                {
                    text: 'Принят к исполнению',
                    value: 'date_accept',
                },
                {
                    text: 'Выполнен',
                    value: 'date_complete',
                },
            ]

            if(!this.userIsPoint){
                this.headers.splice(7,0,
                    {
                        text: '% Комиссии',
                        sortable: true,
                        value: 'percent',
                    },
                    {
                        text: 'К выплате сервисменам',
                        sortable: true,
                        value: 'payForWorker',
                    }
                )
            }

            if (this.$store.state.settings.marketingSourceView) {
                this.headers.splice(1, 0, {
                    text: 'Источник',
                    value: 'options.marketingSource',
                })
            }
        },
        mounted() {
            console.log('orders start!')
            this.dates[0] = moment().format('YYYY-MM-DD')
            this.dates[1] = moment().format('YYYY-MM-DD')
            this.d1 = this.getDate1String()
            this.d2 = this.getDate2String()
            this.updateData()
        },

        beforeDestroy() {},

        destroyed() {
            return this.timer ? clearInterval(this.timer) : false
        },
        watch: {},
        computed: {
            dateRangeText() {
                return this.dates.join(' ~ ')
            },
            deletedOrdersCount() {
                return this.deletedOrdersData.length
            },
            selectRestoreOrder() {
                const result = this.expanded[0]
                return result
            },
            //*** Удаленные заказы конец */

        },
        methods: {
            // *** Удаленные заказы ***
            //сработает после окончания таймера
            notifyDeleteOrder() {
                const order = this.currentDeleteItem
                const user = this.$store.state.user.name
                const userRole = this.$store.state.user.role
                console.log('user name = ', user)
                console.log('user role = ', userRole)
                const data = {
                    order,
                    user,
                    userRole,
                }
                this.$store
                    .dispatch('order_delete_notify', data)
                    .then(res => {
                        if (res.data.success) {
                            console.log('notify ok')
                        }
                    })
                    .catch(err => {
                        console.log('notify false', err.name, err.message)
                    })
            },
            //возвращает удаленный заказ обратно в список
            snackRestoreOrder() {
                this.snackbar = false
                this.snackbarCancelInterval = true
                const restoreOrder = this.currentDeleteItem
                const indexOrder = this.currentDeleteItem.indexOrder
                delete this.currentDeleteItem.indexOrder
                this.disBtn=false;
                return this.orders.splice(indexOrder, 0, restoreOrder)
            },

            //запускает таймер по восстановлению заказа. По окончании отправляет notify в телеграмм бот что заказ удален
            syncPbar() {
                console.log('start syncPbar')

                //останавливаем отправку notify если нажали кнопку восстановления
                if (this.snackbarCancelInterval) {
                    this.snackbarCancelInterval = false
                    return clearTimeout(timerId)
                }

                let timerId = setTimeout(() => {
                    //Increment the time counter by 100
                    this.currentTime += 1000
                    this.syncPbar()
                }, 1000)

                if (this.currentTime > this.snackbarTime) {
                    this.snackbar = false
                }
                if (this.currentTime > this.snackbarTime + 1200 && this.snackbarCancelInterval === false) {
                    clearInterval(timerId)
                    //Запускает удаление
                    this.deleteOrder()
                }
            },

            //получает все удаленные заказы за конкретную дату (так же активируется при срабатывании updateData() )
            getDeletedOrders() { //ИЗМЕНЕН
                // this.loading = true
                this.deletedOrdersData = []
                let options={
                    date1: this.d1,
                    date2: this.d2,
                    deliveryTime: true,
                    service:true
                }
                if(this.userIsService){
                    options.service_id=this.$store.state.user.service_id
                }else if(this.userIsPoint){
                    options.point_id=this.$store.state.user.point_id
                }else{
                    options.service_id=this.filter_service
                }
                this.$store
                    .dispatch('orders_deleted', options)
                    .then(res => {
                        console.log("DELETED",res.data.orders)
                        if (res.data.success) {
                            this.deletedOrdersData = res.data.orders.reverse()
                            //  this.loading = false
                            // console.log('test func revers deleted Orders', this.deletedOrdersData);
                            // console.log('test deletedOrdersLength', this.deletedOrdersData.length);
                        }
                    })
            },

            //получает данные от ребенка (tableDeleted), закрывая попап и обновляя данные если true
            restoreStatus(data) {
                console.log('test restore data = ', data)
                if (data.status) {
                    this.updateData()
                    this.popupDeletedOrder = false
                    return this.restoreSuccess(data.name)
                } else {
                    console.log('restore false')
                    return this.restoreError(data.name)
                }
            },
            restoreSuccess(name) {
                console.log('test restoreSuccess = ', name)
                const text = `Восстановление ${name} прошло успешно`
                this.notifyToast(text, { color: 'info', x: 'right', y: 'bottom', timeout: 1500 })
            },
            restoreError(name) {
                const text = `Ошибка восстановления заказа ${name}`
                this.notifyToast(text, { color: 'error', x: 'right', y: 'bottom', timeout: 1500 })
            },
            setTimer() {
                if (this.count > 0) {
                    setTimeout(() => {
                        this.count -= 1
                        console.log('test count', this.count)
                        this.setTimer()
                    }, 1000)
                }
            },

            // *** Удаленные заказы конец ***

            onClickGuest(order) {
                if (order.guest_id) {
                    this.guestNameClick = true
                    const name = order.guest_name ? order.guest_name + ' ' : ''
                    this.notifyToast('Гость: ' + name + order.guest_phone, { x: 'right', y: 'top', color: 'info', timeout: 4000 })
                    setTimeout(() => {
                        this.guestNameClick = false
                    }, 100)
                }
            },
            onClickGuestCreate(order) {
                this.guestCreateClick = true
                this.$store.state.loading = true

                this.$store
                    .dispatch('guest_create', order)
                    .then(res => {
                        if (res.data.success) {
                            const guest = res.data.guest
                            order.guest_id = guest.id
                            order.guest_name = guest.name
                            order.guest_phone = guest.phone
                            this.notifyToast('Успешно создан гость ' + guest.name, { color: 'success', timeout: 4000 })
                        } else {
                            this.notifyToast('Не удалось создать гостя, нет телефона', { color: 'error', timeout: 4000 })
                        }
                        this.guestCreateClick = false
                        this.$store.state.loading = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.guestCreateClick = false
                        this.$store.state.loading = false
                        this.notifyToast('Не удалось создать гостя, нет телефона', { color: 'error', timeout: 4000 })
                    })
            },
            getGuestPhoneName(order) {
                let guest_name = ''
                if (order.guest_id && order.guest_phone) {
                    guest_name += order.guest_phone
                    if (order.guest_name) guest_name += ' - ' + order.guest_name
                } else {
                    if (order.guest) {
                        guest_name = this.getGuestStr(order.guest).substr(0, 24)
                    }
                }
                return guest_name
            },

            onDeleteBtnDialogEdit() {
                // сохраняем выбранный заказ
                console.log('delete button clicked');
                this.currentDeleteItem = this.currentItem
                this.dialogEdit = false
                this.snackbarStart()
            },
            //вызывает snackbar быстрой отмены удаления
            snackbarStart(index) {
                //отключение кнопок удаления на время snackbar
                console.log('starting snackbar');
                this.disBtn = true
                const orderId = this.currentDeleteItem.id
                // находим индекс заказа, что бы вставить его туда же откуда взяли
                const indexOrder = this.orders.findIndex(order => order.id === orderId)
                //добавляем в заказ на случай восстановления через snackbar
                this.currentDeleteItem.indexOrder = indexOrder
                //удаляем заказ из списка
                this.orders = this.orders.filter(order => order.id != orderId)
                const orderName = this.currentDeleteItem.name
                this.snackbar = true
                this.currentTime = 0
                this.snackbarText = `Заказ ${orderName}`

                //this.syncPbar()
            },
            deleteOrder() {
                console.log('this.currentDeleteItem', this.currentDeleteItem)

                const order = this.currentDeleteItem
                delete order.indexOrder
                order.status_delete = config.order.status.deleted

                this.$store
                    .dispatch('order_delete', order.id)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order delete ok')
                            this.notifyDeleteOrder()
                            this.getDeletedOrders()
                            this.disBtn = false
                        } else {
                            console.log('order delete fail')
                        }
                    })
                    .catch(err => {
                        console.log('order_delete error', err.name, err.message)
                        this.dialogEdit = false
                    })
            },
            onSaveDialogEdit(savedItem) {
                this.selectedItem = JSON.parse(JSON.stringify(savedItem));

                const orderSave = {
                    id: this.selectedItem.id,
                }
                
                if (this.changeFlag) {
                    this.currentItem.options = this.selectedItem.options
                    orderSave.options = JSON.stringify(this.selectedItem.options)
                }

                if (this.currentItem.guest != this.selectedItem.guest) {
                    this.currentItem.guest = this.selectedItem.guest
                    orderSave.guest = this.selectedItem.guest
                    this.changeFlag = true
                }
                if (this.currentItem.finish_addr != this.selectedItem.finish_addr) {
                    this.currentItem.finish_addr = this.selectedItem.finish_addr
                    orderSave.finish_addr = this.selectedItem.finish_addr
                    this.changeFlag = true
                }
                if (this.currentItem.descr != this.selectedItem.descr) {
                    this.currentItem.descr = this.selectedItem.descr
                    orderSave.descr = this.selectedItem.descr
                    this.changeFlag = true
                }
                if (this.currentItem.coordinates != this.selectedItem.coordinates) {
                    this.currentItem.coordinates = this.selectedItem.coordinates
                    orderSave.coordinates = this.selectedItem.coordinates
                    this.changeFlag = true
                }
                if (this.currentItem.worker_tid != this.selectedItem.worker_tid) {
                    this.currentItem.worker_tid = this.selectedItem.worker_tid
                    orderSave.worker_tid = this.selectedItem.worker_tid
                    this.changeFlag = true
                    this.currentItem.worker_name = this.workers.find(w => w.tid == this.currentItem.worker_tid).name
                    if (this.currentItem.worker_name == '< Не назначен >') {
                        this.currentItem.worker_name = '--'
                    }
                }

                const pay_cost = parseInt(this.selectedItem.pay_cost)
                if (!isNaN(this.selectedItem.pay_cost) && pay_cost > 0) {
                    if (this.currentItem.pay_cost != pay_cost) {
                        this.currentItem.pay_cost = pay_cost
                        orderSave.pay_cost = pay_cost
                        this.changeFlag = true
                    }
                }

                const fee_point = parseInt(this.selectedItem.fee_point)
                if (fee_point || fee_point == 0) {
                    if (this.selectedItem.date_complete) {
                        this.ordersFeeService += fee_point - this.currentItem.fee_point
                    }
                    this.currentItem.fee_point = fee_point
                    orderSave.fee_point = fee_point
                    this.changeFlag = true
                }
                this.dialogEdit = false

                if (this.changeFlag) {
                    console.log('save order', orderSave)
                    this.$store
                        .dispatch('order_set', orderSave)
                        .then(res => {
                            if (res.data.success) {
                                console.log('order saved ok')
                            } else {
                                console.log('order saved fail')
                            }
                            this.changeFlag = false
                        })
                        .catch(err => {
                            console.log('order_set error', err.name, err.message)
                        })
                }
            },

            onClickOrderEdit(item) {
                if (this.dialogStatus || this.guestNameClick || this.guestCreateClick) return
                if (item.status == config.order.status.forming) {
                    this.dialogHeader = `Заказ ${item.name} создается...`
                } else {
                    this.dialogHeader = `Заказ ${item.name}`
                }
                // this.dialogHeader = `Заказ ${item.name} /ord${item.id}`
                this.currentItem = item
                this.selectedItem = JSON.parse(JSON.stringify(item))
                this.dialogEdit = true
            },
            onClickOrderStatus(item) {
                this.dialogHeader = `Изменить статус для ${item.name}`
                this.dialogContent = `Текущий статус заказа: <span style="color: ${this.orderStatusColorHtml(item.status)};">${this.orderStatusStr(item.status,true)}</span>`
                this.currentItem = item
                this.dialogStatus = true
            },
            onClickOrderNew() { 
                let worker = this.workers.find(w => w.tid == this.currentItem.worker_tid)
                if (worker) {
                    if (worker.act_ord) {
                        worker.act_ord--
                    }
                    if (worker.act_ord == 0) {
                        worker.status = config.user.status.free
                    }
                    this.$store.dispatch('user_set', worker).then(res => {
                        if (res.data.success) {
                            console.log('user saved ok')
                        } else {
                            console.log('order saved fail')
                        }
                    })
                }

                this.currentItem.status = config.order.status.new
                this.currentItem.worker_tid = null
                this.currentItem.service_id = null
                this.currentItem.date_accept = null
                this.currentItem.date_complete = null

                this.$store
                    .dispatch('order_set', this.currentItem)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order saved ok')
                            this.orders = this.orders.map((order, i) => {
                                if (order.id == this.currentItem.id) {
                                    order.status = this.currentItem.status
                                    order.worker_tid = this.currentItem.worker_tid
                                }
                                return order
                            })
                        } else {
                            console.log('order saved fail')
                        }
                        this.dialogStatus = false
                    })
                    .catch(err => {
                        console.log('order_set error', err.name, err.message)
                        this.dialogStatus = false
                    })
            },
            onClickOrderComplete() { 
                let worker = this.workers.find(w => w.tid == this.currentItem.worker_tid)
               
                if (worker) {
                    if (worker.act_ord) {
                        worker.act_ord--
                    }
                    if (worker.act_ord == 0) {
                        worker.status = config.user.status.free
                    }

                    this.$store.dispatch('user_set', worker).then(res => {
                        if (res.data.success) {
                            console.log('user saved ok')
                        } else {
                            console.log('order saved fail')
                        }
                    })
                }

                this.currentItem.status = config.order.status.complete
                this.currentItem.date_take = this.currentItem.date_ready
                this.currentItem.date_complete = this.currentItem.date_delivery

                this.$store
                    .dispatch('order_set', this.currentItem)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order saved ok')
                            this.orders = this.orders.map((order, i) => {
                                if (order.id == this.currentItem.id) {
                                    order.status = this.currentItem.status
                                    order.date_complete = this.currentItem.date_complete
                                }
                                return order
                            })
                        } else {
                            console.log('order saved fail')
                        }
                        this.dialogStatus = false
                    })
                    .catch(err => {
                        console.log('order_set error', err.name, err.message)
                        this.dialogStatus = false
                    })
            },

            onClickOrderDelete() {
                this.currentItem.status = config.order.status.deleted

                this.$store
                    .dispatch('order_delete', this.currentItem.id)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order delete ok')
                            this.orders = this.orders.filter(order => order.id != this.currentItem.id)
                        } else {
                            console.log('order delete fail')
                        }
                        this.dialogStatus = false
                    })
                    .catch(err => {
                        console.log('order_delete error', err.name, err.message)
                        this.dialogStatus = false
                    })
            },
            onClickChangeDate(arg) {
                this.d1 = this.getDate1StringFromDate(this.dates[0])
                this.d2 = this.getDate2StringFromDate(this.dates[1])
                this.updateData()
            },
            onClickDownloadData() {
                let operator_tid
                let point_id
                if (!this.userIsPoint&&!this.userIsService) {
                    operator_tid = this.$store.state.user.tid
                    point_id = this.filter_point
                } else if(this.userIsService){
                    operator_tid = this.$store.state.user.operator_tid
                    point_id = this.filter_point
                }else {
                    operator_tid = this.$store.state.user.operator_tid
                    point_id = this.$store.state.user.point_id
                }
                let condition=''

                if(this.userIsService){
                    let service_id = this.$store.state.user.service_id
                    condition=`&service_id=${service_id.toFixed(0).toString()}`
                }else{
                    condition=`&operator_tid=${operator_tid}`
                }
                let url = `${config.api.delivery}service_orders_export?tz=${this.$store.state.settings.tz}&tz_string=${this.$store.state.settings.tz_string}${condition}&date1=${this.d1}&date2=${this.d2}&point_id=${point_id}&worker=${this.filter_worker}&service_id=${this.filter_service}&mode=${this.filter_order_mode}&deliveryTime=true`
                if (!this.userIsPoint) {
                    url += '&operator=1'
                }
                console.log(url)
                window.location.href = url
            },
            onClickToday() {
                this.dates[0] = moment().format('YYYY-MM-DD')
                this.dates[1] = moment().format('YYYY-MM-DD')

                this.d1 = this.getDate1String()
                this.d2 = this.getDate2String()
                this.updateData()
            },
            onClickYesterday() {
                this.dates[0] = moment().subtract(1, 'd').format('YYYY-MM-DD')
                this.dates[1] = moment().subtract(1, 'd').format('YYYY-MM-DD')

                this.d1 = this.getDate1String(-1)
                this.d2 = this.getDate2String(-1)
                this.updateData()
            },
            onClickMonth() {
                this.dates[0] = moment().subtract(30, 'd').format('YYYY-MM-DD')
                this.dates[1] = moment().format('YYYY-MM-DD')

                this.d1 = this.getDate1String(-30)
                this.d2 = this.getDate2String()
                this.updateData()
            },
            onClickUpdate() {
                this.updateData()
            },
            getGuestStr(guest) {
                let splittedStr = guest.split('Телефон:')
                return splittedStr.length > 1 ? splittedStr[0] + ' ' + splittedStr[1].substr(0, 17) : guest
            },
            getTdCatted(str) {
                return '<td>Hello</td>'
            },
            pointNameGet: function (point_id) {
                let point_name = '--'
                const point = this.points.find(point => point.id == point_id)
                if (point) {
                    point_name = point.name
                }
                return point_name
            },
            getRandomInt() {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5
            },

            updateData() { 
                this.loading = true
                console.log('this.filter_worker', this.filter_worker)
                this.workers = []
                let loading_count = 0
                let options = {
                    date1: this.d1,
                    date2: this.d2,
                    worker: this.filter_worker,
                    mode: this.filter_order_mode,
                    deliveryTime: true,
                    service:true
                }
                if(this.userIsService){
                    options.service_id=this.$store.state.user.service_id
                }else if(this.userIsPoint){
                    options.point_id=this.$store.state.user.point_id
                }else{
                    options.service_id=this.filter_service
                }
                this.$store
                    .dispatch('orders_date', options)
                    .then(res => {
                        if (res.data.success) {
                            this.orders = res.data.orders.reverse()

                            for (let order of this.orders) {
                                if (order.status != config.order.status.new&&order.status != config.order.status.pending) {
                                    this.workers.push({
                                        tid: order.worker_tid,
                                        full_name: order.worker_name,
                                    })
                                }
                            }

                            if (this.userIsService) {
                                this.workers_list = this.workers.map(w => ({ tid: w.tid, name: w.full_name }))
                                this.workers_list.splice(0, 0, { tid: 'null', name: '< Не назначен >' })

                                this.filter_workers = this.workers.map(w => ({ tid: w.tid, name: w.full_name }))
                                this.filter_workers.splice(0, 0, { tid: 0, name: '< Все >' })
                                this.filter_workers.splice(1, 0, { tid: 'null', name: '< Не назначен >' })
                            }

                            console.log('orders_date', this.orders)
                            
                            this.orderStatuses[config.order.status.forming] = 0
                            this.orderStatuses[config.order.status.new] = 0
                            this.orderStatuses[config.order.status.passed] = 0
                            this.orderStatuses[config.order.status.way_point] = 0
                            this.orderStatuses[config.order.status.way_guest] = 0
                            this.orderStatuses[config.order.status.complete] = 0
                            this.orderStatuses[config.order.status.pending] = 0
                            this.orderStatuses[config.order.status.passed] = 0

                            for(let order of this.orders){
                                this.orderStatuses[order.status]++
                                order.pay_cost = isNaN(Number(order.pay_cost))?'-------------':Number(order.pay_cost) 
                                this.$store.dispatch('service_get',order.service_id).then(res=>{
                                    order.percent=''
                                    order.payForWorker=''
                                    if(res.data.success){
                                        let service = res.data.service
                                        order.percent = service.settings.percent
                                        order.payForWorker = Number(order.pay_cost)-(Number(order.pay_cost)/100)*Number(order.percent)
                                    }
                                })
                                order.file_link=''
                                // if(order.media!=null){
                                //     this.$store
                                //         .dispatch('get_file', order.media.media_id)
                                //         .then(res => {
                                //         }).catch(err => {
                                //             console.log(err)
                                //         })
                                // }
                                
                            }

                            this.ordersComplete = this.orderStatuses[config.order.status.complete]
                            this.ordersFeeService = this.orders.filter(x=>x.status==config.order.status.complete)
                                                             .reduce((sum,x)=>sum+Number(x.pay_cost),0)

                        } else {
                            this.ordersComplete = 0
                            this.ordersFeeService = 0
                        }
                        loading_count++
                    })
                    .catch(err => {
                        console.log('3 error', err.name, err.message)
                        loading_count++
                    })

                this.$store
                    .dispatch('services_get')
                    .then(res => {
                        if (res.data.success) {
                            this.services = res.data.services
                            console.log('services', this.services)
                        }
                        this.filter_services = this.services.map(s => ({ id: s.id, name: s.name }))
                        this.filter_services.splice(0, 0, { id: 0, name: '< Все >' })
                        loading_count++
                    })
                    .catch(err => {
                        console.log('2 error', err.name, err.message)
                        loading_count++
                    })

                this.$store
                    .dispatch('points_get_all')
                    .then(res => {
                        if (res.data.success) {
                            this.points = res.data.points
                            console.log('points', this.services)
                        }
                        this.filter_points = this.points.map(p => ({ id: p.id, name: p.name }))
                        this.filter_points.splice(0, 0, { id: 0, name: '< Все >' })
                        loading_count++
                    })
                    .catch(err => {
                        console.log('2 error', err.name, err.message)
                        loading_count++
                    })

                if (!this.userIsService) {
                    this.$store
                        .dispatch('workers_get_all')
                        .then(res => {
                            if (res.data.success) {
                                this.workers = res.data.workers
                                console.log('workers', this.workers)
                            }

                            this.workers_list = this.workers.map(w => ({ tid: w.tid, name: w.full_name }))
                            this.workers_list.splice(0, 0, { tid: 'null', name: '< Не назначен >' })

                            this.filter_workers = this.workers.map(w => ({ tid: w.tid, name: w.full_name }))
                            this.filter_workers.splice(0, 0, { tid: 0, name: '< Все >' })
                            this.filter_workers.splice(1, 0, { tid: 'null', name: '< Не назначен >' })
                            console.log('filter_workers', this.filter_workers)
                            // this.loading = false
                            loading_count++
                        })
                        .catch(err => {
                            console.log('1 error', err.name, err.message)
                            loading_count++
                        })
                } else {
                    loading_count++
                }

                // получение удаленных заказов
                this.getDeletedOrders()

                const loadingControl = () => {
                    console.log('loading_count', loading_count)
                    if (loading_count < 3) {
                        setTimeout(() => loadingControl(), 500)
                    } else {
                        this.loading = false
                    }
                }
                loadingControl()
            },
            rowItemClass(item) {
                return item.status == config.order.status.forming ? 'rowItemDisable' : ''
            },
            closeCalendar() {
                this.menu_calendar = false
            },
            clickFeeUpdate() {
                this.promptFeeUpdate = true
            },
            promptFeeUpdateYes() {
                this.promptFeeUpdate = false
                this.feeUpdate()
            },
            feeUpdate() {
                this.loading = true
                this.$store
                    .dispatch('orders_fee_calculate', {
                        date1: this.d1,
                        date2: this.d2,
                        // mode: 0,
                    })
                    .then(res => {
                        this.loading = false
                        if (res.data.success) {
                            this.updateData()
                        }
                    })
                    .catch(err => {
                        console.log('2 error', err.name, err.message)
                        this.loading = false
                    })
            },
            distance(d) {
                console.log('distance', d)
                const distance = parseFloat(d)
                if (distance) {
                    return (distance / 1000).toFixed(1) + ' км'
                }
                return '--'
            },
            marketingSource(m) {
                if (m) {
                    return m
                }
                return '--'
            },
        },
    }
</script>

<style lang="stylus">

    .rowItemDisable
        cursor default !important
        background #fff !important

        [role="button"]
            cursor default !important

    .orders
        height 100%
        padding-top 10px !important

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    white-space nowrap
                    padding 0 8px

            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                td
                    white-space nowrap
                    padding 0 8px
                    font-size 0.75rem
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px
                    td.tooltip-text
                        padding-left 0
                    td
                        padding-left 0

        .v-tooltip__content
            background #222
            color #fff
            border-radius 6px
            padding 5px 10px 4px
            &:hover
                cursor pointer

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px
                margin-left 0
                cursor pointer
                padding 0 6px

        .v-chip.v-size--x-small
            height 18px

        b
            font-weight 500

        .guest-error
            color #F44336

        .guest-selectable
            font-weight 400
            color #0D47A1
            cursor pointer
            &:hover
                text-decoration: underline
</style>
